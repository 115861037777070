<template>
	<S2SForm title="CIT Providers">
		<S2SCard title="Edit CIT Provider">
			<S2SFormGenerator ref="citForm" :schema="citSchema" :data="model"></S2SFormGenerator>
			<v-flex class="text-xs-left text-right">
				<v-btn text :to="{ name: 'cit-providers' }" class="mr-2">Cancel</v-btn>
				<v-btn color="accent" @click="updateProvider" :disabled="status.loading">Update</v-btn>
			</v-flex>
		</S2SCard>
	</S2SForm>
</template>

<script>
import Vue from "vue";
import citSchema from "@/static/config/forms/cit-schema.json";
import { CITBody } from "../../api/safes-api";

export default Vue.extend({
	name: "CITProviderEdit",

	data: function() {
		return {
			citSchema: citSchema,
			model: {}
		};
	},

	computed: {
		citProviders: function() {
			return this.$store.state.safes.citProviders;
		},
		status: function() {
			return this.$store.state.safes.status;
		}
	},

	created: function() {
		this.$store.dispatch("safes/fetchCITProviders");
	},

	mounted: function() {
		setTimeout(() => {
			for (let provider of this.citProviders) {
				if (this.$route.params.providerId == provider.id.toString()) {
					this.model = provider;
				}
			}
		}, 500);
	},

	methods: {
		async updateProvider() {
			const form = this.$refs["citForm"];
			if (await form.validate()) {
				if (this.$store.dispatch("safes/updateCITProvider", form.model)) {
					this.$router.push({ name: "cit-providers" });
				}
			}
		}
	}
});
</script>
